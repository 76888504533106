/**
 * Shared public environment config
 */
export const ENV_CONFIG = {
  firebase: {
    "projectId": "steam-centar",
    "appId": "1:313384417938:web:92ac15a1a2d3721b52444b",
    "storageBucket": "steam-centar.appspot.com",
    "locationId": "europe-west",
    "apiKey": "AIzaSyBK7V62qRwdCifHcL_HITcDsgLBsgmthhg",
    "authDomain": "steam-centar.firebaseapp.com",
    "messagingSenderId": "313384417938",
    "measurementId": "G-TPN3MC5TSC"
  }
};
